<template>
  <section>
    <Card class="card">
      <template #title>
        <div class="title">
          <p>Informações de risco</p>
        </div>
      </template>
      <template #subtitle>
        <div class="subtitle">
          <p>Informe dos dados de risco do segurado</p>
        </div>
      </template>
      <template #content>
        <div>
          <div class="grid grid-cols-2 gap-6">
            <OnpointSelect
              label="Grupo de modalidade"
              placeholder="Selecione"
              :required="true"
              :items="modalidadesList"
              optionLabel="Name"
              v-model="modalidade"
            ></OnpointSelect>
            <OnpointSelect
              label="Modalidade principal"
              placeholder="Selecione"
              :required="true"
              :items="modalidadePrincipalList"
              optionLabel="Name"
              v-model="modalidadePrincipal"
              :disabled="modalidade ? false : true"
            ></OnpointSelect>
          </div>
        </div>
        <div v-if="modalidadePrincipal">
          <div class="mt-8">
            <CoberturasAdicionais :modalidadePrincipalUniqueId="modalidadePrincipal.UniqueId"/>
          </div>
          <div class="mt-8">
            <DadosDaProposta
              :modalidadePrincipal="modalidadePrincipal"
            ></DadosDaProposta>
          </div>
          <div class="mt-8" v-if="proposta.InsuredAmountValue && proposta.DeadlineDays">
            <div>
              <DadosDoObjeto/>
            </div>
            <div>
              <DadosModalidadeComplementar/>
            </div>
            <div>
              <CondicoesParticulares/>
            </div>            
          </div>
          
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end">
          <Button
            label="Voltar"
            class="p-button-rounded cancel-button"
            @click="prevPage()"
          />
          <Button
            label="Próximo"
            class="p-button-rounded"
            @click="nextPage()"
          />
        </div>
      </template>
    </Card>
  </section>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import OnpointSelect from "@/components/onpoint-select/index.vue";
import CoberturasAdicionais from "./components/CoberturasAdicionais.vue";
import DadosDaProposta from "./components/DadosDaProposta.vue";
import DadosDoObjeto from './components/DadosDoObjeto.vue';
import DadosModalidadeComplementar from './components/DadosModalidadeComplementar.vue';
import CondicoesParticulares from './components/CondicoesParticulares.vue';


import axiosInstance from "@/axios";
import { mapGetters, mapMutations} from "vuex";



export default {
  props: {
    formData: Object,
  },
  components: {
    Card,
    Button,
    OnpointSelect,
    CoberturasAdicionais,
    DadosDaProposta,
    DadosDoObjeto,
    DadosModalidadeComplementar,
    CondicoesParticulares
  },
  data() {
    return {
      modalidade: null,
      modalidadePrincipal: null,
      modalidadesList: [],
      modalidadePrincipalList: [],     
    };
  },
  async mounted() {
    await this.getGrupoModalidades();
  },
  watch: {
    modalidade(value) {
      this.modalidadePrincipal = null;
      this.modalidadePrincipalList = null;  
      this.updateProposta({
        ...this.proposta,          
        DeadlineDays: null,
        StartDate : null,
        EndDate : null,
        InsuredAmountValue : null,
        InsurancePremium : null,
        ComplementaryDeadlineDays: null,
        ComplementaryStartDate : null,
        ComplementaryEndDate : null, 
        Modality:null,
        ModalityId:null
      }) 
      if(value){
        this.grupoModalidadeSelecionado(value);
      }
    },
    modalidadePrincipal(value) {     
      this.updateProposta({
        ...this.proposta,          
        DeadlineDays: null,
        StartDate : null,
        EndDate : null,
        InsuredAmountValue : null,
        InsurancePremium : null,
        ComplementaryDeadlineDays: null,
        ComplementaryStartDate : null,
        ComplementaryEndDate : null, 
        Modality:null,
        ModalityId:null
      }) 
      if (value) {
        this.modalidadePrincipalSelecionada(value);       
      }
    },
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
  },
  methods: {
    nextPage() {
      this.$emit("nextPage", { formData: {}, pageIndex: 2 });
    },
    prevPage() {
      this.$emit("prevPage", { pageIndex: 2 });
    },

    ...mapMutations("proposta-module", ["updateProposta"]),
    async getGrupoModalidades() {
      return await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `/api/Core/GetModalityGroupsEnabledToInsured?InsuredUniqueId=${this.formData.seguradoUniqueId}&PolicyHolderUniqueId=${this.formData.tomadorUniqueId}`
          )
          .then((response) => {
            this.modalidadesList = response.data.Response;
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    getModalidadePorGrupo(uniqueGrupoId) {
      return this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/Core/GetModalities?ModalityGroupUniqueId=${uniqueGrupoId}&PolicyHolderUniqueId=${this.formData.tomadorUniqueId}&InsuredUniqueId=${this.formData.seguradoUniqueId}`
          )
          .then((response) => {
            this.modalidadePrincipalList = response.data.Response;
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    getCondicoesMercado() {
      return axiosInstance
        .get(
          `/api/PolicyHolder/GetPolicyHolderMarketTerm?PolicyHolderUniqueId=${this.formData.tomadorUniqueId}&modalityId=${this.modalidadePrincipal.Id}`
        )
        .then((response) => {
          this.condicoesMercado = response.data.Response;
        })
        .catch((errors) =>
          this.$onpoint.errorModal(errors.response.data.Errors)
        );
    },

    async getPercentageLimitPremiumRelationInsuredAmount() {
      return await axiosInstance
        .get(`api/Core/GetPercentageLimitPremiumRelationIssuredAmount`)
        .then((response) => {
          this.percLimit = response.data.Response;
        })
        .catch((errors) => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },

    getModalidadeComplementar(modalidadeUniqueId) {
      return axiosInstance
        .get(
          `/api/Core/GetComplementaryModalities?ModalityUniqueId=${modalidadeUniqueId}&ignoreCache=true&ignoreDeleted=true`
        )
        .then((response) => {
          this.modalidadeComplementar = response.data.Response;
        })
        .catch((errors) =>
          this.$onpoint.errorModal(errors.response.data.Errors)
        );
    },

    garantirConsistenciaDeDatas() {
      this.updateProposta({
        ...this.proposta,
        ComplementaryStartDate: this.vigenciaFimPrincipal,
      });    
    },

    async getModalidadeObjetoComplementar(modalidadeComplementar) {
      if (!(modalidadeComplementar || {}).Id) {
        this.importanciaSeguradaComplementar = undefined;
        this.vigenciaInicioComplementar = undefined;
        this.vigenciaDiasComplementar = undefined;
        this.vigenciaFimComplementar = undefined;
        this.modalidadeComplementarObjeto = {};
        return Promise.resolve(undefined);
      }

      if (this.modalidadePrincipal.EqualizeValidityDate) {
        this.garantirConsistenciaDeDatas();
      }

      return await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `/api/Core/GetModalityObject?ModalityId=${modalidadeComplementar.Id}`
          )
          .then((response) => {
            this.modalidadeComplementarObjeto = response.data.Response;
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    grupoModalidadeSelecionado(grupo) {
      this.updateProposta({
        ...this.proposta,
        ModalityGroupId: grupo.Id,
        ModalityGroup: grupo,
      });
      return this.getModalidadePorGrupo(grupo.UniqueId);
    },

    modalidadePrincipalSelecionada(modalidadePrincipal) {
      this.updateProposta({
        ...this.proposta,
        ModalityId: (this.modalidadePrincipal || {}).Id,
        ComplementaryModalityId: undefined,
        ComplementaryModality: undefined,
        Modality: this.modalidadePrincipal,
        Beneficiaries: [],
      });
      if (!this.modalidadePrincipal) return;

      const promises = [];
      
      //
      //verificar necessidade
      //
      this.getCondicoesMercado();
      //
      //verificar necessidade
      //
      promises.push(this.getPercentageLimitPremiumRelationInsuredAmount());

      //
      // colocar esse if apenas quando for criar o componente de modalidade complementar
      //
      if (modalidadePrincipal.HasComplementaryModality) {
        promises.push(
          this.getModalidadeComplementar(modalidadePrincipal.UniqueId)
        );
      }

      this.$onpoint.loading(() => {
        return Promise.all(promises);
      });
      //
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  width: 100%;
}
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.divider {
  padding: 10px;
}
.p-dropdown {
  width: 100%;
}
.cancel-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
}
</style>