<template>
  <div class="mt-8">
    <div>
      <p class="title">Condições particulares</p>
    </div>
    <div>
      <p class="subtitle">
        Adicione as condições particulares abaixo conforme necessário.
      </p>
    </div>
    <div
      v-for="(clausula, i) in condicoesParticularesAdicionadas"
      :key="i"
      class="flex"
    >
      <div class="card mt-8">
        <OnpointSelect
          v-if="clausula && !clausula.Name"
          :id="`onpoint-select${i}`"
          class="mt-5 w-1/2"
          label="Selecione uma condição particular"
          placeholder="Selecione"
          :items="condicoesParticularesList"
          optionLabel="Name"
          @input="setInputsParticularClauses($event, i)"          
        />
        <div class="mt-8" v-if="clausula.Name && clausula.Campos[0]">
          <p class="title">Dados do(a) {{ clausula.Name }}</p>
        </div>
        <div class="flex gap-5 mt-5">
          <OnpointInput
            v-for="(item, indexCampos) in clausula.Campos"
            :key="indexCampos"
            :label="item.Label"
            :required="true"
            class="mb-5 w-1/3"
            v-model="item.Value"
            @input="handleFunctionInput(clausula, i)"
          />
        </div>
        <Divider />
        <Accordion class="accordion mt-8" v-if="clausula.ContractualTerm">
          <AccordionTab
            :header="`Visualizar clausulas das condições ${clausula.Name}`"
          >
            <div class="p-5" v-html="clausula.HtmlFormatted" />
          </AccordionTab>
        </Accordion>
      </div>
      <div class="actions-item-particular-clauses mt-8">
        <Button class="trash-button" @click="removeClause(clausula,i)"><i class="onpoint-trash"></i></Button>
      </div>
    </div>

    <div class="mt-5 card flex justify-center">
      <Button class="white-button" @click="addNewClause()"
        >Adicionar nova condição particular</Button
      >
    </div>
  </div>
</template>

<script>
import OnpointInput from "@/components/onpoint-input";
import Divider from "primevue/divider";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import OnpointSelect from "@/components/onpoint-select";

import { mapGetters, mapMutations } from "vuex";
import coreProvider from "@/providers/core-provider";
import TagHelpers from "@/helpers/TagHelpers";

export default {
  components: {
    OnpointInput,
    Divider,
    Accordion,
    AccordionTab,
    Button,
    OnpointSelect,
  },
  data() {
    return {
      condicoesParticularesList: [],
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta", "particularClauses"]),
    defaultTags() {
      return TagHelpers.defaultTags(this.proposta);
    },
    condicoesParticularesAdicionadas: {
      get() {
        return this.particularClauses;
      },
      set(val) {
        this.updateParticularClauses(val);
      },
    },
  },
  mounted() {
    this.condicoesParticularesAdicionadas = [{}];
    this.getParticularClauses();
  },
  methods: {
    ...mapMutations("proposta-module", ["updateParticularClauses"]),
    async getParticularClauses() {
      this.condicoesParticularesList = await coreProvider
        .getProposalParticularClauses(this.proposta.ModalityId)
        .then((data) => {
          return data.data.Response;
        });
    },
    setInputsParticularClauses(clausula, index) {   
      if (!clausula) {
        let clone = this.$utils.deepClone(
          this.condicoesParticularesAdicionadas
        );
        clone[index] = {};
        this.condicoesParticularesAdicionadas[index] = clone;
        return this.$forceUpdate();
      }      
      let inputs = [];
      const tagInputs = this.getTagInputs(clausula);

      if (tagInputs.length > 0) inputs = tagInputs;

      if (clausula.ContractualTerm && clausula.ContractualTerm.Id) {
        this.condicoesParticularesAdicionadas[index] = {
          Object: clausula,
          ContractualTermId: clausula.ContractualTerm.Id,
          ParticularClauseId: clausula.Id,
          AggregatorClause: clausula.AggregatorClause,
          Name: clausula.Name,
          Campos: inputs,
          Value: clausula.ContractualTerm.Value,
          ContractualTerm: {
            Value: clausula.ContractualTerm.Value,
          },
        };
      }
      this.getProposalTermsValues(
        this.condicoesParticularesAdicionadas[index],
        index
      );
      this.formatParticularClausesHtml(
        this.condicoesParticularesAdicionadas[index],
        index
      );
      this.$forceUpdate();
    },

    getTagInputs(particularClause) {
            
      if (!particularClause) return;
      const jsonTagList = particularClause.ContractualTerm.JSONTag
        ? JSON.parse(particularClause.ContractualTerm.JSONTag)
        : [];
  
      let exists =
        this.condicoesParticularesAdicionadas.filter(
          (input) =>
            input.ContractualTermId == particularClause.ContractualTermId
        ).length > 0;

      if (exists) return [];

      const values = particularClause.ContractualTerm.JSONTagValue
        ? JSON.parse(particularClause.ContractualTerm.JSONTagValue)
        : {};

      (jsonTagList || []).forEach((element) => {
        element.Value = values[element.Name];
      });

      const jsonTagInputs = (
        jsonTagList.filter((input) => !["Automatic"].includes(input.Type)) || []
      ).map((input) => {
        input.JSONTag = particularClause.ContractualTerm.JSONTag;
        input.ContractualTermId = particularClause.ContractualTerm.Id;
        return input;
      });

      return jsonTagInputs;
    },
    formatParticularClausesHtml(clausula, index) {
      if (!clausula) return;
      let clauseObject = clausula.ContractualTerm.Value;
      clausula.Campos.map((input) => {
        if (input.Type === "Date" && input.Value) {
          let dateValue = new Date(input.Value);
          dateValue = dateValue.toLocaleDateString("pt-BR");
          clauseObject = clauseObject.replaceAll(
            `[${input.Name}]`,
            `<span class="font-semibold text-danger">${dateValue}</span>`
          );
        } else {
          clauseObject = input.Value
            ? (clauseObject || "").replaceAll(
                `[${input.Name}]`,
                `<span class="font-semibold text-danger">${input.Value}</span>`
              )
            : (clauseObject || "").replaceAll(
                `[${input.Name}]`,
                `<span class="font-semibold text-danger">${
                  input.Type == "Automatic"
                    ? "[" + input.Name + "]"
                    : input.Label
                }</span>`
              );
        }
        clausula.Value = clauseObject;
      });
      if (clausula.Value) {
        this.condicoesParticularesAdicionadas[index].HtmlBase = clausula.Value;
      }
      this.replaceDefaultTags(index);
      this.setTagValues(clausula);
    },
    replaceDefaultTags(index) {
      const ignoreTags = (this.condicoesParticularesAdicionadas || []).map(
        (input) => {
          return input.Name;
        }
      );

      this.condicoesParticularesAdicionadas[index].HtmlFormatted =
        TagHelpers.replaceDefaultTags(
          this.condicoesParticularesAdicionadas[index].HtmlBase,
          this.defaultTags,
          ignoreTags
        );
    },
    setTagValues(clausula) {
      if (!clausula) return;

      let jsonTagValue =
        JSON.parse(clausula.ContractualTerm.JSONTagValue) || {};
      let properties = Object.entries(jsonTagValue);

      if (properties.length == 0) return;

      (properties || []).forEach(([key, value]) => {
        let input = clausula.Campos.find((input) => input.Name == key);
        if (input && !input.Value) input.Value = value;
      });
      this.$forceUpdate();
    },
    handleFunctionInput(clausula, index) {
      this.mountParticularClausesHtml(clausula, index);
    },
    mountParticularClausesHtml: _.debounce(function (clausula, index) {
      this.formatParticularClausesHtml(clausula, index);
    }, 500),
    getProposalTermsValues(clausula, index) {
      if (!clausula) return;

      let clauseIds = [];

      let valueContract = clausula.ContractualTerm.Value;
      let ignoreTagsList = [];
      ignoreTagsList = ignoreTagsList.concat(
        (clausula.Campos || []).map((input) => {
          return input.Name;
        })
      );
      const ignoreTags = ignoreTagsList;

      valueContract = TagHelpers.replaceDefaultTags(
        valueContract,
        this.defaultTags,
        ignoreTags
      );
      let valutesTags = {};
      if (clausula.Object.ContractualTerm.JSONTag) {
        JSON.parse(clausula.Object.ContractualTerm.JSONTag).map((tag) => {
          clausula.Campos.map((input) => {
            if (input.Type === "Date") {
              var dateValue = new Date(input.Value);
              dateValue = dateValue.toLocaleDateString("pt-BR");
              valueContract = valueContract.replaceAll(
                `[${input.Name}]`,
                `${dateValue || ""}`
              );
            } else {
              valueContract = valueContract.replaceAll(
                `[${input.Name}]`,
                `${input.Value || ""}`
              );

              if (valueContract.indexOf(`[${input.Name}_EXT]`) >= 0) {
                const valueExt = TagHelpers.parseTagNumberValueExtenso(
                  input.Value
                );
                if (valueExt)
                  valueContract = valueContract.replaceAll(
                    `[${input.Name}_EXT]`,
                    valueExt
                  );
              }
            }
            if (tag.Name === input.Name) {
              if (input.Type === "Date") {
                valutesTags = {
                  ...valutesTags,
                  [input.Name]: dateValue || "",
                };
              } else {
                valutesTags = {
                  ...valutesTags,
                  [input.Name]: input.Value || "",
                };
              }
            }
          });
        });
      }
      if (valutesTags) {
        this.condicoesParticularesAdicionadas[
          index
        ].ContractualTerm.JSONTagValue = JSON.stringify(valutesTags);
      }

      let proposalTermsValues = {
        proposalId: this.proposta ? this.proposta.Id : null,
        JSONTag: clausula.ContractualTerm.JSONTag,
        ContractualTermId: clausula.ContractualTermId,
        JSONTagValue: JSON.stringify(valutesTags),
        Value: valueContract,
      };

      return proposalTermsValues;
    },
    addNewClause() {
      this.condicoesParticularesAdicionadas = [
        ...this.condicoesParticularesAdicionadas,
        {},
      ];
    },
    removeClause(clausula,index){ 
      let clone = this.$utils.deepClone(
          this.condicoesParticularesAdicionadas
        );
        clone.splice(index, 1);
        this.condicoesParticularesAdicionadas = clone;        
        return this.$forceUpdate()
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.card {
  background: #f9fafb;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
  .title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}
.accordion {
  :deep(.p-accordion-header-text) {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #6b7280;
  }
}
.white-button {
  color: black;
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 20px;
  border-radius: 100px;
}
.trash-button {
  height: 62px;
  margin-left: 30px;
  width: 40px;
  background: rgba(251, 178, 191, 1);
  border-radius: 8px !important;
  i:before {
    position: relative;
    top: 2px;
    font-size: 24px;
  }
  border-color: #f88c9e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.trash-button:hover {
  background: rgba(251, 178, 191, 0.8);
}
</style>