<template>
  <div>
    <div>
      <p class="title">Dados da proposta</p>
    </div>
    <div class="flex gap-4">
      <OnpointInputCurrency
        class="mt-8 w-1/4"
        label="Valor de importancia segurada (IS)"
        v-model="importanciaSegurada"
        :required="true"
        @change="calculoDataFimVigenciaPrincipal()"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Data de inicio da vigencia"
        v-model="dataDeInicioVigenciaPrincipal"
        :required="true"
        :calendar="true"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Prazo em dias"
        :placeholderValue="`Prazo em dias`"
        v-model="prazoDeVigencia"
        :required="true"
        @change="calculoDataFimVigenciaPrincipal()"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Data de final da vigencia"
        v-model="dataFimVigenciaPrincipal"
        :required="true"
        :calendar="true"
        :calendarDisabled="true"
      />
    </div>
  </div>
</template>

<script>
import OnpointInput from "@/components/onpoint-input";
import OnpointInputCurrency from "@/components/onpoint-input-currency";

import { mapGetters, mapMutations} from "vuex";
import axiosInstance from "@/axios";
export default {
  props: {
    modalidadePrincipal: Object,
  },
  components: {
    OnpointInput,
    OnpointInputCurrency
  },
  data() {
    return {};
  }, 
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    prazoDeVigencia: {
      get() {
        return this.proposta.DeadlineDays;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          DeadlineDays: val,
        });
      },
    },
    dataDeInicioVigenciaPrincipal: {
      get() {
        return this.proposta.StartDate;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          StartDate: val,
        });
      },
    },
    dataFimVigenciaPrincipal: {
      get() {
        return this.proposta.EndDate;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          EndDate: val,
        });
      },
    },
    importanciaSegurada: {
      get() {
        return this.proposta.InsuredAmountValue;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsuredAmountValue: Number(val),
        });
      },
    },
    premio: {
      get() {
        return this.proposta.InsurancePremium;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsurancePremium: val,
        });
      },
    },
  },
  methods: {
    ...mapMutations("proposta-module", ["updateProposta"]),

    calculoDataFimVigenciaPrincipal() {
      if (!this.prazoDeVigencia) return;
      if (!this.importanciaSegurada) return;

      if (Number(this.prazoDeVigencia) < 0) {
        this.prazoDeVigencia = undefined;
        this.$onpoint.errorModal("Prazo de dias não pode ser menor que zero.");
        return;
      }

      const minPeriod = (this.modalidadePrincipal || {}).MinPeriodInDays || 0;
      const maxPeriod = (this.modalidadePrincipal || {}).MaxPeriodInDays || 0;

      if (minPeriod > 0 && this.prazoDeVigencia < minPeriod) {
        this.prazoDeVigencia = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser menor que ${minPeriod}.`
        );
      }

      if (maxPeriod > 0 && this.prazoDeVigencia > maxPeriod) {
        this.prazoDeVigencia = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser maior que ${maxPeriod}.`
        );
        return;
      }

      this.dataFimVigenciaPrincipal = this.calculoDias(
        this.dataDeInicioVigenciaPrincipal,
        this.dataFimVigenciaPrincipal,
        this.prazoDeVigencia
      );

      this.vigenciaFimPrincipal = this.dataFimVigenciaPrincipal
        ? this.dataFimVigenciaPrincipal.toJSON()
        : null;
      this.calculoValorPremio(6);         
    },
    calculoDias(dataInicio, dataFim, dias) {
      const inicio = this.$moment(dataInicio);

      if (inicio) {
        if (inicio && dias) {
          dataFim = new Date(inicio.add(dias, "days"));
          return dataFim;
        }
      }
    },

    async calculoValorPremio(type) {
      let dados = this.proposta;
      dados.BrokerId = this.$store.state["auth"].userInfo.BrokerId
        ? this.$store.state["auth"].userInfo.BrokerId
        : 0;

      let datePayload = {
        PrincipalModalityDate: {
          EndDate: type == 7 ? dados.EndDate : null,
          DeadlineDays: type == 7 ? null : dados.DeadlineDays,
          StartDate: dados.StartDate,
        },
      };

      this.$vs.loading();
      return await axiosInstance
        .post(`api/Proposal/GetCalculationProposalDate`, datePayload)
        .then(async (response) => {
          await this.$onpoint.loading(async () => {
            return await axiosInstance({
              method: "post",
              url: "api/RestoreInsurancePremium/GetProposalInsurancePremium",
              data: dados,
            })
              .then((response) => {
                this.$vs.loading.close();
                this.premio = response.data.Response.InsurancePremium;
                this.updateProposta({
                  ...this.proposta,
                  InsurancePremiumPrincipalModality:
                    response.data.Response.InsurancePremiumPrincipalModality,
                  InsurancePremiumComplementaryModality:
                    response.data.Response.InsurancePremiumComplementarModality,
                });
              })
              .catch((errors) => {
                this.$vs.loading.close();
                this.$onpoint.errorModal(errors.response.data.Errors);
                return Promise.reject(errors);
              });
          });
        })
        .catch((data) => {
          this.$vs.loading.close();
          return Promise.reject(data);
        });
    },
  }, 
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.required {
  color: rgba(180, 23, 58, 1);
}
</style>