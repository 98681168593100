<template>
  <div v-if="modalidadesComplementarList">
    <div>
      <p class="title">Dados da modalidade complementar</p>
    </div>
    <div class="grid grid-cols-2 gap-6">
      <OnpointSelect
        class="mt-8"
        label="Tipo de modalidade complementar"
        placeholder="Selecione"        
        :items="modalidadesComplementarList"
        optionLabel="Name"
        v-model="selectedModalidadeComplementar"
      ></OnpointSelect>
    </div>
    <div class="flex gap-4" v-if="selectedModalidadeComplementar">
      <OnpointInput
        class="mt-8 w-1/4"
        label="Valor de importancia segurada (IS)"
        v-model="importanciaSeguradaComplementar"
        :required="true"
        :currency="true"
        @input="formatarTextoObjeto()"
        @change="calculoDataFimVigenciaComplementar()"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Data de inicio da vigencia"
        v-model="dataDeInicioVigenciaComplementar"
        :required="true"
        :calendar="true"
        :calendarDisabled="true"
        @input="formatarTextoObjeto()"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Prazo em dias"
        v-model="prazoDeVigenciaComplementar"
        :required="true"
        @input="formatarTextoObjeto()"
        @change="calculoDataFimVigenciaComplementar()"
      />
      <OnpointInput
        class="mt-8 w-1/4"
        label="Data de final da vigencia"
        v-model="dataFimVigenciaComplementar"
        :required="true"
        :calendar="true"
        :calendarDisabled="true"
        @input="formatarTextoObjeto()"
      />
    </div>

    <div class="mt-8">
      <div class="flex gap-4">
        <template v-for="(tag, index) in camposObjetoComplementar">
          <OnpointInput
            :key="index"
            v-if="tag.Type != 'Automatic'"
            :label="tag.Label"
            class="w-1/4"
            :required="true"            
            @input="
              ($event) => {
                setObject({ [tag.Name]: $event });
                tag.Value = $event;
                formatarTextoObjeto();
              }
            "
          />
        </template>
      </div>
      <Accordion class="accordion mt-8" v-if="textoObjetoHtml">
        <AccordionTab header="Visualizar objeto da modalidade complementar">
          <div v-html="textoObjetoHtml" class="p-5"></div>
        </AccordionTab>
      </Accordion>
    </div>
    <Divider class="divider"></Divider>
  </div>
</template>

<script>
import OnpointSelect from "@/components/onpoint-select";
import OnpointInput from "@/components/onpoint-input";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Divider from "primevue/divider";

import { mapGetters, mapMutations} from "vuex";
import axiosInstance from "@/axios";
import TagHelpers from "@/helpers/TagHelpers";
export default {
  components: {
    OnpointSelect,
    OnpointInput,
    Accordion,
    AccordionTab,
    Divider,
  },
  data() {
    return {
      modalidadesComplementarList: null,
      selectedModalidadeComplementar: null,
      modalidadeComplementarObjeto: null,
      textoObjetoHtml: null,
      camposObjetoComplementar: [],
      objetoComplementar: {},
      tagsSeguradora: [],
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),

    importanciaSeguradaComplementar: {
      get() {
        return this.proposta.ComplementaryInsuredAmountValue;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryInsuredAmountValue: val,
        });
      },
    },

    dataDeInicioVigenciaComplementar: {
      get() {
        return this.proposta.ComplementaryStartDate;
      },
      set() {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryStartDate: this.proposta.EndDate,
        });
      },
    },

    dataFimVigenciaComplementar: {
      get() {
        return this.proposta.ComplementaryEndDate;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryEndDate: val,
        });
      },
    },

    prazoDeVigenciaComplementar: {
      get() {
        return this.proposta.ComplementaryDeadlineDays;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryDeadlineDays: val,
        });
      },
    },

    premio: {
      get() {
        return this.proposta.InsurancePremium;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsurancePremium: val,
        });
      },
    },
  },
  mounted() {
    this.getModalidadeComplementar();
  },
  watch: {
    selectedModalidadeComplementar(value) {
      if (value) {
        if (this.proposta.Modality.EqualizeValidityDate) {
          this.garantirConsistenciaDeDatas();
        }
        this.stepsAfterSelectComplementarModalidade();
      }
      else{
        this.camposObjetoComplementar=[]
        this.textoObjetoHtml=null
      }
    },
  },
  methods: {
    ...mapMutations("proposta-module", ["updateProposta"]),
    getModalidadeComplementar() {
      return axiosInstance
        .get(
          `/api/Core/GetComplementaryModalities?ModalityUniqueId=${this.proposta.Modality.UniqueId}&ignoreCache=true&ignoreDeleted=true`
        )
        .then((response) => {
          this.modalidadesComplementarList = response.data.Response;
        })
        .catch((errors) =>
          this.$onpoint.errorModal(errors.response.data.Errors)
        );
    },
    async modalidadeComplementarSelecionada() {
      this.updateProposta({
        ...this.proposta,
        ComplementaryModalityId: (this.selectedModalidadeComplementar || {}).Id,
        ComplementaryModality: this.selectedModalidadeComplementar,
      });

      await this.getModalidadeObjetoComplementar();
    },

    async getModalidadeObjetoComplementar() {
      return await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `/api/Core/GetModalityObject?ModalityId=${this.selectedModalidadeComplementar.Id}`
          )
          .then((response) => {
            this.modalidadeComplementarObjeto = response.data.Response;
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    garantirConsistenciaDeDatas() {
      this.dataFimVigenciaComplementar = this.proposta.EndDate;
      this.dataDeInicioVigenciaComplementar = this.proposta.EndDate;
    },
    setObject(value) {
      this.objetoComplementar = { ...this.objetoComplementar, ...value };
    },
    formatarTextoObjeto(initValue) {
      this.textoObjetoHtml = this.formatTextDefaultTags();

      this.atualizarPropostaTermos(initValue);

      this.$forceUpdate();
    },
    formatTextDefaultTags() {
      let allTags = [];
      let textHtml = "";

      if (this.modalidadeComplementarObjeto || {}) {
        if ((this.modalidadeComplementarObjeto || {}).JSONTag)
          allTags = allTags.concat(
            eval(this.modalidadeComplementarObjeto.JSONTag) || []
          );

        if (
          this.modalidadeComplementarObjeto &&
          this.modalidadeComplementarObjeto.Value
        )
          textHtml += `\n${this.modalidadeComplementarObjeto.Value}\n`;
      }

      this.defaultTags().forEach((tag) => {
        const defaultTagVal = tag.Value;
        delete tag["value"];
        allTags.push(tag);

        this.objetoComplementar[tag.Name] = defaultTagVal;
      });

      if (this.tagsSeguradora && this.tagsSeguradora.length > 0) {
        this.tagsSeguradora.forEach((tag) => {
          allTags.push(tag);
          this.objetoComplementar[tag.Name] = tag.Value;
        });
      }

      if (
        this.camposObjetoComplementar &&
        this.camposObjetoComplementar.length > 0
      ) {
        this.camposObjetoComplementar.forEach((tag) => {
          allTags.push(tag);
        });
      }
      textHtml = TagHelpers.replaceTags(
        allTags,
        textHtml,
        this.objetoComplementar
      );

      textHtml = textHtml.replaceAll(/(?:\r\n|\r|\n)/g, "<br>");

      return textHtml;
    },
    atualizarPropostaTermos(initValue) {
      let propostaTermos = [];
      let tags = null;

      let auxObjeto = {};
      let auxValue = "";

      if ((this.modalidadeComplementarObjeto || {}).Value) {
        auxObjeto = {};
        auxValue = this.modalidadeComplementarObjeto.Value;

        tags = null;
        if ((this.modalidadeComplementarObjeto || {}).JSONTag)
          tags = eval(this.modalidadeComplementarObjeto.JSONTag || []);

        if ((tags || []).length > 0) {
          Object.getOwnPropertyNames(this.objetoComplementar).forEach(
            (objetoTag) => {
              if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
                auxValue = auxValue.replaceAll(
                  `[${objetoTag}]`,
                  this.objetoComplementar[objetoTag]
                );

                auxObjeto[objetoTag] = this.objetoComplementar[objetoTag];
              }

              tags.forEach((tag) => {
                if (objetoTag == tag.Name)
                  auxObjeto[objetoTag] = this.objetoComplementar[objetoTag];
              });
            }
          );
        }

        const idx = propostaTermos.findIndex(
          (item) =>
            item.ContractualTermId == this.modalidadeComplementarObjeto.Id
        );
        if (idx > -1) {
          propostaTermos[idx] = {
            ...propostaTermos[idx],
            Value: auxValue,
            JSONTagValue: JSON.stringify(auxObjeto),
          };
        } else {
          propostaTermos.push({
            ContractualTermId: this.modalidadeComplementarObjeto.Id,
            Value: auxValue,
            JSONTagValue: JSON.stringify(auxObjeto),
          });
        }
      }
    //verificar se o coberturas adicionasi deveria estar aqui
      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          auxObjeto = null;
          auxValue = coberturaAdicionalObjeto.Value;
          tags = null;

          if ((coberturaAdicionalObjeto || {}).JSONTag)
            tags = eval(coberturaAdicionalObjeto.JSONTag || []);

          if ((tags || []).length > 0) {
            auxObjeto = {};

            Object.getOwnPropertyNames(this.objetoComplementar).forEach(
              (objetoTag) => {
                if (this.objetoComplementar[objetoTag]) {
                  if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
                    auxValue = auxValue.replaceAll(
                      `[${objetoTag}]`,
                      this.objetoComplementar[objetoTag]
                    );

                    auxObjeto[objetoTag] = this.objetoComplementar[objetoTag];
                  }

                  tags.forEach((tag) => {
                    if (objetoTag == tag.Name)
                      auxObjeto[objetoTag] = this.objetoComplementar[objetoTag];
                  });
                }
              }
            );
          }

          const idx = propostaTermos.findIndex(
            (item) => item.ContractualTermId == coberturaAdicionalObjeto.Id
          );

          if (idx > -1) {
            propostaTermos[idx] = {
              ...propostaTermos[idx],
              Value: auxValue,
              JSONTagValue: !!auxObjeto ? JSON.stringify(auxObjeto) : null,
            };
          } else {
            propostaTermos.push({
              ContractualTermId: coberturaAdicionalObjeto.Id,
              Value: auxValue,
              JSONTagValue: !!auxObjeto ? JSON.stringify(auxObjeto) : null,
            });
          }
        });
      }
      this.updatePropostaTermos({
        termos: propostaTermos,
        initValue: initValue,
        object: JSON.stringify(this.objetoComplementar),
      });
    },
    async loadGetCompanyProposalTags() {
      return await axiosInstance
        .get("api/Proposal/GetCompanyProposalTags")
        .then((response) => {
          this.tagsSeguradora = response.data.Response;
        });
    },
    defaultTags() {
      return TagHelpers.defaultTags(
        this.proposta,
        this.tagsSeguradora.map((x) => {
          return { [x.Name]: x.Value };
        })
      );
    },
    updatePropostaTermos(value) {
      if (!value.initValue)
        this.updateProposta({
          ...this.proposta,
          ProposalContractualTermsModalityComplementary:value.termos,
          ObjectJSON: value.object,
        });
    },
    async stepsAfterSelectComplementarModalidade() { 
      await this.modalidadeComplementarSelecionada(
        this.selectedModalidadeComplementar
      );
      this.loadGetCompanyProposalTags();
      this.defaultTags();
      this.montarCamposObjetoComplementar();
      this.formatarTextoObjeto(true);
    },

    montarCamposObjetoComplementar() {
      (eval((this.modalidadeComplementarObjeto || {}).JSONTag) || []).forEach(
        (modalidadeComplementarObjetoTag) => {
          const idxFound = this.camposObjetoComplementar.findIndex((campo) => {
            return campo.Name == modalidadeComplementarObjetoTag.Name;
          });
          if (idxFound === -1)
            this.camposObjetoComplementar.push(modalidadeComplementarObjetoTag);
        }
      );

      if ((this.modalidadeComplementarObjeto || {}).JSONTagValue)
        this.objeto = {
          ...this.objeto,
          ...JSON.parse(
            (this.modalidadeComplementarObjeto || {}).JSONTagValue || ""
          ),
        };

      if ((this.modalidadeComplementarObjeto || {}).JSONTag) {
        if ((this.modalidadeComplementarObjeto || {}).JSONTagValue)
          this.objeto = {
            ...this.objeto,
            ...JSON.parse(this.modalidadeComplementarObjeto.JSONTagValue || ""),
          };

        (eval(this.modalidadeComplementarObjeto.JSONTag) || []).forEach(
          (modalidadeComplementarObjetoTag) => {
            const idxFound = this.camposObjetoComplementar.findIndex(
              (campo) => {
                return campo.Name == modalidadeComplementarObjetoTag.Name;
              }
            );
            if (idxFound === -1)
              this.camposObjetoComplementar.push(
                modalidadeComplementarObjetoTag
              );
          }
        );
      }

      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          if ((coberturaAdicionalObjeto || {}).JSONTag) {
            if ((coberturaAdicionalObjeto || {}).JSONTagValue)
              this.objeto = {
                ...this.objeto,
                ...JSON.parse(coberturaAdicionalObjeto.JSONTagValue || ""),
              };

            (eval(coberturaAdicionalObjeto.JSONTag) || []).forEach(
              (objetoTag) => {
                const idxFound = this.camposObjetoComplementar.findIndex(
                  (campo) => {
                    return campo.Name == objetoTag.Name;
                  }
                );
                if (idxFound === -1)
                  this.camposObjetoComplementar.push(objetoTag);
              }
            );
          }
        });
      }
      this.$forceUpdate();
    },   
    calculoDataFimVigenciaComplementar() {
      if (
        !this.prazoDeVigenciaComplementar ||
        !this.importanciaSeguradaComplementar
      )
        return;

      if (this.prazoDeVigenciaComplementar < 0) {
        this.prazoDeVigenciaComplementar = undefined;
        this.$onpoint.errorModal("Prazo de dias não pode ser menor que zero.");
        return;
      }
      const minPeriod =
        (this.selectedModalidadeComplementar || {}).MinPeriodInDays || 0;
      const maxPeriod =
        (this.selectedModalidadeComplementar || {}).MaxPeriodInDays || 0;
      if (minPeriod > 0 && this.prazoDeVigenciaComplementar < minPeriod) {
        this.prazoDeVigenciaComplementar = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser menor que ${minPeriod}.`
        );
        return;
      }

      if (maxPeriod > 0 && this.prazoDeVigenciaComplementar > maxPeriod) {
        this.prazoDeVigenciaComplementar = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser maior que ${maxPeriod}.`
        );
        return;
      }

      this.calculoDiasVigenciaComplementar();
      

      this.calculoValorPremio(5);
    },
    calculoDiasVigenciaComplementar() {
      this.dataFimVigenciaComplementar = this.calculoDias(
        this.dataDeInicioVigenciaComplementar,
        this.dataFimVigenciaComplementar,
        this.prazoDeVigenciaComplementar
      );
      this.calculoValorPremio(4);
    },

    async calculoValorPremio(type) {
      let dados = this.proposta;

      dados.BrokerId = this.$store.state["auth"].userInfo.BrokerId
        ? this.$store.state["auth"].userInfo.BrokerId
        : 0;

      let datePayload = {
        PrincipalModalityDate: {
          EndDate: type == 7 ? dados.EndDate : null,
          DeadlineDays: type == 7 ? null : dados.DeadlineDays,
          StartDate: dados.StartDate,
        },
      };
      this.$vs.loading();
      return await axiosInstance
        .post(`api/Proposal/GetCalculationProposalDate`, datePayload)
        .then(async (response) => {
          await this.$onpoint.loading(async () => {
            return await axiosInstance({
              method: "post",
              url: "api/RestoreInsurancePremium/GetProposalInsurancePremium",
              data: dados,
            })
              .then((response) => {
                this.$vs.loading.close();
                this.premio = response.data.Response.InsurancePremium;
                this.updateProposta({
                  ...this.proposta,
                  InsurancePremiumPrincipalModality:
                    response.data.Response.InsurancePremiumPrincipalModality,
                  InsurancePremiumComplementaryModality:
                    response.data.Response.InsurancePremiumComplementarModality,
                });
              })
              .catch((errors) => {
                this.$onpoint.errorModal(errors.response.data.Errors);
                this.$vs.loading.close();
              });
          });
        })
        .catch((data) => {
          this.$vs.loading.close();
          return Promise.reject(data);
        });
    },
    calculoDias(dataInicio, dataFim, dias) {
      const inicio = this.$moment(dataInicio);

      if (inicio) {
        if (inicio && dias) {
          dataFim = new Date(inicio.add(dias, "days"));
          return dataFim;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.accordion {
  :deep(.p-accordion-header-text) {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #6b7280;
  }
}
</style>