<template>
  <div>
    <div>
      <p class="title">Coberturas adicionais</p>
    </div>
    <div>
      <p class="subtitle">
        Selecione as coberturas que deverão estar presentes no objeto desta
        apólice
      </p>
    </div>
    <div class="mt-5">
      <div class="flex checkbox mt-2" v-for="(item, i) in coberturas" :key="i">
        <div class="content-center">
          <Checkbox
            id="city1"
            inputId="city1"
            name="city"
            :value="item"
            v-model="coberturasAdicionais"
            class="mt-2 mr-2"
          />
        </div>
        <div>
          <label for="city3" class="mt-1 ml-2 label">Cobertura</label>
          <p class="description mt-1 ml-2">{{ item.Name }}</p>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";

import { mapGetters, mapMutations} from "vuex";
import axiosInstance from "@/axios";

export default {
  props: {
   modalidadePrincipalUniqueId:String
  },
  components: {
    Checkbox,
  },
  data() {
    return {
      coberturas:[],
      coberturasAdicionais: [],
      coberturasAdicionaisObject: [],
    };
  },
  watch: {
    coberturasAdicionais(value) {
      if (value) {
        this.atualizaCoberturas();
      }
    },
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    premio: {
      get() {
        return this.proposta.InsurancePremium;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsurancePremium: val,
        });
      },
    },
  },
  created(){
    this.getCoberturas()
  },
  methods: {
    ...mapMutations("proposta-module", ["updateProposta"]),
     async getCoberturas() {
      return await axiosInstance
        .get(
          `api/Core/GetAdditionalCoverages?ModalityUniqueId=${this.modalidadePrincipalUniqueId}&ignoreCache=true&ignoreDeleted=true`
        )
        .then((response) => {
          this.coberturas = response.data.Response;
        })
        .catch((errors) =>
          this.$onpoint.errorModal(errors.response.data.Errors)
        );
    },
    async atualizaCoberturas() {
      let mapCoberturas = [];
      if (this.coberturasAdicionais && this.coberturasAdicionais.length > 0)
        mapCoberturas = this.coberturasAdicionais.map((item) => {
          return { CoverageId: item.Id,Name:item.Name };
        });

      this.$store.commit("proposta-module/updateProposta", {
        ...this.proposta,
        ProposalCoverages: mapCoberturas,
      });
      if (this.premio) {
        this.calculoValorPremio(3);
      }
      await this.getCoberturasAdicionaisObjeto();
    },
    async getCoberturasAdicionaisObjeto() {
      let mapCoberturas = [];
      if (this.coberturasAdicionais && this.coberturasAdicionais.length > 0)
        mapCoberturas = this.coberturasAdicionais.map((item) => {
          return item.Id;
        });

      return await axiosInstance({
        method: "post",
        url: "/api/Core/GetAdditionalCoveragesObjects",
        data: mapCoberturas,
      })
        .then((response) => {
          this.coberturasAdicionaisObject = response.data.Response;
        })
        .catch((errors) =>
          this.$onpoint.errorModal(errors.response.data.Errors)
        );
    },

    async calculoValorPremio(type) {
      let dados = this.proposta;
      dados.BrokerId = this.$store.state["auth"].userInfo.BrokerId
        ? this.$store.state["auth"].userInfo.BrokerId
        : 0;

      let datePayload = {
        PrincipalModalityDate: {
          EndDate: type == 7 ? dados.EndDate : null,
          DeadlineDays: type == 7 ? null : dados.DeadlineDays,
          StartDate: dados.StartDate,
        },
      };

      this.$vs.loading();
      return await axiosInstance
        .post(`api/Proposal/GetCalculationProposalDate`, datePayload)
        .then(async (response) => {
          await this.$onpoint.loading(async () => {
            return await axiosInstance({
              method: "post",
              url: "api/RestoreInsurancePremium/GetProposalInsurancePremium",
              data: dados,
            })
              .then((response) => {
                this.$vs.loading.close();
                this.premio = response.data.Response.InsurancePremium;
                this.updateProposta({
                  ...this.proposta,
                  InsurancePremiumPrincipalModality:
                    response.data.Response.InsurancePremiumPrincipalModality,
                  InsurancePremiumComplementaryModality:
                    response.data.Response.InsurancePremiumComplementarModality,
                });
              })
              .catch((errors) => {
                this.$onpoint.errorModal(errors.response.data.Errors);
                this.$vs.loading.close();
              });
          });
        })
        .catch((errors) => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(errors.response.data.Errors);
          return Promise.reject(errors);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.checkbox {
  .label {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .description {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>