<template>
  <div>
    <div>
      <p class="title">Dados do objeto</p>
    </div>
    <div>
      <div class="flex gap-4">
        <OnpointInput
          v-for="(tag, index) in camposObjeto"
          :key="index"
          v-model="tag.valueEvent"
          class="mt-8 w-1/2"
          :label="tag.Label"
          :required="true"
          @input="setTagValueFromEvent(tag)"
        />

      </div>
      <div class="mt-8">
        <Accordion class="accordion">
          <AccordionTab header="Objeto da apólice">
            <div v-html="textoObjetoHtml" class="p-5"></div>
          </AccordionTab>
        </Accordion>
      </div>
      <Divider class="divider"></Divider>
    </div>
  </div>
</template>

<script>
import OnpointInput from "@/components/onpoint-input";
import Divider from "primevue/divider";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

import axiosInstance from "@/axios";
import { mapGetters, mapMutations} from "vuex";
import TagHelpers from "@/helpers/TagHelpers";

export default {
  components: {
    OnpointInput,
    Divider,
    Accordion,
    AccordionTab
  },
  data() {
    return {
      modalidadeObjeto: null,
      coberturasAdicionaisObjeto: null,
      camposObjeto: [],
      tagsSeguradora: [],
      objeto: {},
      textoObjetoHtml: null,
      modalidadeComplementarObjeto: null,
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
  },
  watch: {
    "proposta.ProposalCoverages"(novoValor, valorAntigo) {
      if (novoValor !== valorAntigo) {
      this.getCoberturasAdicionaisObjeto();
      this.formatarTextoObjeto(true);
      }
    },
    modalidadeObjeto() {
      this.montarCamposObjeto();
      this.formatarTextoObjeto(true);
    },
  },
  mounted() {
    this.getModalidadeObjeto();
    this.getCoberturasAdicionaisObjeto();
    this.defaultTags();
    this.loadGetCompanyProposalTags();
  },
  methods: {
    ...mapMutations("proposta-module", ["updateProposta"]),
    async getModalidadeObjeto() {
      return await axiosInstance
        .get(
          `/api/Core/GetModalityObject?ModalityId=${this.proposta.ModalityId}`
        )
        .then((response) => {
          this.modalidadeObjeto = response.data.Response;
        })
        .catch((errors) =>
          this.$onpoint.errorModal(errors.response.data.Errors)
        );
    },
    setTagValueFromEvent(tag){
      this.setObject({ [tag.Name]: tag.valueEvent });
      tag.Value = tag.valueEvent;
      this.formatarTextoObjeto();
    },
    async getCoberturasAdicionaisObjeto() {
      let mapCoberturas = [];
      if (this.proposta.ProposalCoverages && this.proposta.ProposalCoverages[0])
        mapCoberturas = this.proposta.ProposalCoverages.map((item) => {
          return item.CoverageId;
        });

      return await axiosInstance({
        method: "post",
        url: "/api/Core/GetAdditionalCoveragesObjects",
        data: mapCoberturas,
      })
        .then((response) => {
          this.coberturasAdicionaisObjeto = response.data.Response;
           this.$store.commit("proposta-module/updateProposta", {
            ...this.proposta,
            ProposalCoveragesObjects: this.coberturasAdicionaisObjeto
          });
        })
        .catch((errors) =>
          this.$onpoint.errorModal(errors.response.data.Errors)
        );
    },
    defaultTags() {
      return TagHelpers.defaultTags(
        this.proposta,
        this.tagsSeguradora.map((x) => {
          return { [x.Name]: x.Value };
        })
      );
    },

    async loadGetCompanyProposalTags() {
      return await axiosInstance
        .get("api/Proposal/GetCompanyProposalTags")
        .then((response) => {
          this.tagsSeguradora = response.data.Response;
        });
    },

    formatTextDefaultTags() {
      let allTags = [];
      let textHtml = "";

      if (this.modalidadeObjeto || {}) {
        if ((this.modalidadeObjeto || {}).JSONTag)
          allTags = allTags.concat(eval(this.modalidadeObjeto.JSONTag) || []);

        if (this.modalidadeObjeto.Value)
          textHtml += this.modalidadeObjeto.Value;
      }

      if (this.modalidadeComplementarObjeto || {}) {
        if ((this.modalidadeComplementarObjeto || {}).JSONTag)
          allTags = allTags.concat(
            eval(this.modalidadeComplementarObjeto.JSONTag) || []
          );

        if (
          this.modalidadeComplementarObjeto &&
          this.modalidadeComplementarObjeto.Value
        )
          textHtml += `\n${this.modalidadeComplementarObjeto.Value}\n`;
      }

      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        if (this.Excelsior) {
          textHtml += `<br/>A Modalidade e a Importância Segurada dispostas na Descrição da Garantia contemplam as <b>COBERTURAS ADICIONAIS</b> descritas abaixo.
        <br/>Não obstante a previsão de Limite Máximo de Indenização por cobertura contratada, em caso de sinistro, fica certo e ajustado que a soma de todas as Indenizações não poderá ultrapassar o Limite Máximo de Garantia, que para os fins desta apólice coincide com a IMPORTÂNCIA SEGURADA descrita no frontispício da apólice.
        <br/>A(s) cobertura(a) adicional(is) garante(m) o prazo prescricional nos termos do art. 7º, XXIX da Constituição da República, conforme condições especiais de cada cobertura, disposta(s) na presente apólice.<br/>`;
        }
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          if (coberturaAdicionalObjeto && coberturaAdicionalObjeto.Value) {
            textHtml += "<br/>" + coberturaAdicionalObjeto.Value;
          }
        });
      }

      textHtml +=
        "<br/>Esta apólice é emitida de acordo com as condições da Circular da Susep n.º 662/22.";

      this.defaultTags().forEach((tag) => {
        const defaultTagVal = tag.Value;
        delete tag["value"];
        allTags.push(tag);

        this.objeto[tag.Name] = defaultTagVal;
      });

      if (this.tagsSeguradora && this.tagsSeguradora.length > 0) {
        this.tagsSeguradora.forEach((tag) => {
          allTags.push(tag);
          this.objeto[tag.Name] = tag.Value;
        });
      }

      if (this.camposObjeto && this.camposObjeto.length > 0) {
        this.camposObjeto.forEach((tag) => {
          allTags.push(tag);
        });
      }
      textHtml = TagHelpers.replaceTags(allTags, textHtml, this.objeto);

      textHtml = textHtml.replaceAll(/(?:\r\n|\r|\n)/g, "<br>");

      return textHtml;
    },

    montarCamposObjeto() {
      (eval((this.modalidadeObjeto || {}).JSONTag) || []).forEach(
        (modalidadeComplementarObjetoTag) => {
          const idxFound = this.camposObjeto.findIndex((campo) => {
            return campo.Name == modalidadeComplementarObjetoTag.Name;
          });
          if (idxFound === -1)
            this.camposObjeto.push(modalidadeComplementarObjetoTag);
        }
      );

      if ((this.modalidadeObjeto || {}).JSONTagValue)
        this.objeto = {
          ...this.objeto,
          ...JSON.parse((this.modalidadeObjeto || {}).JSONTagValue || ""),
        };

      if ((this.modalidadeComplementarObjeto || {}).JSONTag) {
        if ((this.modalidadeComplementarObjeto || {}).JSONTagValue)
          this.objeto = {
            ...this.objeto,
            ...JSON.parse(this.modalidadeComplementarObjeto.JSONTagValue || ""),
          };

        (eval(this.modalidadeComplementarObjeto.JSONTag) || []).forEach(
          (modalidadeComplementarObjetoTag) => {
            const idxFound = this.camposObjeto.findIndex((campo) => {
              return campo.Name == modalidadeComplementarObjetoTag.Name;
            });
            if (idxFound === -1)
              this.camposObjeto.push(modalidadeComplementarObjetoTag);
          }
        );
      }

      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          if ((coberturaAdicionalObjeto || {}).JSONTag) {
            if ((coberturaAdicionalObjeto || {}).JSONTagValue)
              this.objeto = {
                ...this.objeto,
                ...JSON.parse(coberturaAdicionalObjeto.JSONTagValue || ""),
              };

            (eval(coberturaAdicionalObjeto.JSONTag) || []).forEach(
              (objetoTag) => {
                const idxFound = this.camposObjeto.findIndex((campo) => {
                  return campo.Name == objetoTag.Name;
                });
                if (idxFound === -1) this.camposObjeto.push(objetoTag);
              }
            );
          }
        });
      }

      this.$forceUpdate();
    },
    formatarTextoObjeto(initValue) {
      this.textoObjetoHtml = this.formatTextDefaultTags();

      this.atualizarPropostaTermos(initValue);

      this.$forceUpdate();
    },

    setObject(value) {
      this.objeto = { ...this.objeto, ...value };
    },
    updatePropostaTermos(value) {
      if (!value.initValue)
        this.updateProposta({
          ...this.proposta,
          ProposalContractualTerms: value.termos,
          ObjectJSON: value.object,
        });

    },
    atualizarPropostaTermos(initValue) {

      let propostaTermos = [];
      let tags = null;

      let auxObjeto = {};
      let auxValue = "";


      if ((this.modalidadeObjeto || {}).JSONTag) {
        tags = eval(this.modalidadeObjeto.JSONTag || []);
        auxValue = this.modalidadeObjeto.Value;

        if ((tags || []).length > 0) {
          Object.getOwnPropertyNames(this.objeto).forEach((objetoTag) => {
            if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
              auxValue = auxValue.replaceAll(
                `[${objetoTag}]`,
                this.objeto[objetoTag]
              );
              auxObjeto[objetoTag] = this.objeto[objetoTag];
            }

            tags.forEach((tag) => {
              if (objetoTag == tag.Name)
                auxObjeto[objetoTag] = this.objeto[objetoTag];
            });
          });
        }

        propostaTermos.push({
          ContractualTermId: this.modalidadeObjeto.Id,
          Value: auxValue,
          JSONTagValue: JSON.stringify(auxObjeto),
        });
      }

      if ((this.modalidadeComplementarObjeto || {}).Value) {
        auxObjeto = {};
        auxValue = this.modalidadeComplementarObjeto.Value;

        tags = null;
        if ((this.modalidadeComplementarObjeto || {}).JSONTag)
          tags = eval(this.modalidadeComplementarObjeto.JSONTag || []);

        if ((tags || []).length > 0) {
          Object.getOwnPropertyNames(this.objeto).forEach((objetoTag) => {
            if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
              auxValue = auxValue.replaceAll(
                `[${objetoTag}]`,
                this.objeto[objetoTag]
              );

              auxObjeto[objetoTag] = this.objeto[objetoTag];
            }

            tags.forEach((tag) => {
              if (objetoTag == tag.Name)
                auxObjeto[objetoTag] = this.objeto[objetoTag];
            });
          });
        }

        const idx = propostaTermos.findIndex(
          (item) =>
            item.ContractualTermId == this.modalidadeComplementarObjeto.Id
        );
        if (idx > -1) {
          propostaTermos[idx] = {
            ...propostaTermos[idx],
            Value: auxValue,
            JSONTagValue: JSON.stringify(auxObjeto),
          };
        } else {
          propostaTermos.push({
            ContractualTermId: this.modalidadeComplementarObjeto.Id,
            Value: auxValue,
            JSONTagValue: JSON.stringify(auxObjeto),
          });
        }
      }

      if ((this.coberturasAdicionaisObjeto || []).length > 0) {
        this.coberturasAdicionaisObjeto.forEach((coberturaAdicionalObjeto) => {
          auxObjeto = null;
          auxValue = coberturaAdicionalObjeto.Value;
          tags = null;

          if ((coberturaAdicionalObjeto || {}).JSONTag)
            tags = eval(coberturaAdicionalObjeto.JSONTag || []);

          if ((tags || []).length > 0) {
            auxObjeto = {};

            Object.getOwnPropertyNames(this.objeto).forEach((objetoTag) => {
              if (this.objeto[objetoTag]) {
                if (auxValue.indexOf(`[${objetoTag}]`) > -1) {
                  auxValue = auxValue.replaceAll(
                    `[${objetoTag}]`,
                    this.objeto[objetoTag]
                  );

                  auxObjeto[objetoTag] = this.objeto[objetoTag];
                }

                tags.forEach((tag) => {
                  if (objetoTag == tag.Name)
                    auxObjeto[objetoTag] = this.objeto[objetoTag];
                });
              }
            });
          }

          const idx = propostaTermos.findIndex(
            (item) => item.ContractualTermId == coberturaAdicionalObjeto.Id
          );

          if (idx > -1) {
            propostaTermos[idx] = {
              ...propostaTermos[idx],
              Value: auxValue,
              JSONTagValue: !!auxObjeto ? JSON.stringify(auxObjeto) : null,
            };
          } else {
            propostaTermos.push({
              ContractualTermId: coberturaAdicionalObjeto.Id,
              Value: auxValue,
              JSONTagValue: !!auxObjeto ? JSON.stringify(auxObjeto) : null,
            });
          }
        });
      }
      this.updatePropostaTermos({
        termos: propostaTermos,
        initValue: initValue,
        object: JSON.stringify(this.objeto),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}
.divider {
  padding: 10px;
}
.accordion {
  :deep(.p-accordion-header-text) {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #6b7280;
  }
}
</style>
